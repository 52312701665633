import { useSelector } from 'react-redux'
import { orderDeliveryTypeSelector } from '@/selectors/selectors'
import { useCallback } from 'react'

const DeliveryBoxChoice = ({ title, icon, left, changeDeliveryType }) => {
    const orderDeliveryType = useSelector(orderDeliveryTypeSelector)
    const checked = title.toUpperCase() === orderDeliveryType.text.toUpperCase()

    const changeDelivery = useCallback(() => {
        changeDeliveryType(title.toUpperCase())
    }, [changeDeliveryType, title])

    return (
        <div
            className={`flex-1 hover:cursor-pointer pl-4 pt-4 pb-4 sm:pl-7
        flex items-center  sm:justify-start text-left uppercase 
        ${
            left
                ? 'xs:rounded-tl-xl xs:mr-0.5 rounded-tr-xl rounded-tl-xl mb-0.5 xs:mb-0 xs:rounded-tr-none'
                : 'xs:rounded-tr-xl'
        } 
        ${checked ? 'bg-white' : 'bg-turbo-grayer hover:bg-turbo-gray'}`}
            onClick={changeDelivery}
        >
            <div className={'mr-5 lg:mr-12 min-w-9'}>{icon}</div>
            <div className={`text-xs lg:text-base leading-15px pr-3 font-libre_franklin `}>{title}</div>
        </div>
    )
}
export default DeliveryBoxChoice

import { connect, useDispatch, useSelector } from 'react-redux'
import { Fragment, useCallback, useEffect, useState } from 'react'
import DeliveryBox from '@/ui/components/orderBox/DeliveryBox'
import House from '@/ui/icons/House'
import Region from '@/ui/components/region/brand/Region'
import TitleBlock from '@/ui/components/arrows/TitleBlock'
import Banner from '@/ui/components/banner/Banner'
import { AxiosClient } from '@/axios/axios.client'
import SquaresPattern from '@/ui/icons/SquaresPattern'
import BranchModal from '@/ui/components/modal/BranchModal'
import { BannerTypeEnum, FooterTypeEnum } from '@/constants/constants'
import { useRouter } from 'next/router'
import Layout from '@/ui/components/shared/Layout'
import { performScroll, setupScrollElement } from '@/functions/ui/scroller'
import PopUpContainer from '@/ui/components/popup/PopUpContainer'
import { scrollSelector } from '@/selectors/selectors'
import { openModalAsNewPage, openPromoPage } from '@/functions/modal/modalControls'
import { setMenu } from '@/redux/actions/menuAction'
import { captureException } from '@sentry/nextjs'

export async function getServerSideProps() {
    let data = null
    await AxiosClient.get('/v1/web/hp/load')

        .then(res => {
            data = res.data.data
        })
        .catch(err => {
            captureException(err)
            console.error(err)
        })

    if (!data)
        return {
            notFound: true
        }
    else
        return {
            props: {
                data
            }
        }
}

const Index = ({ data }) => {
    console.debug(data)
    const router = useRouter()
    const dispatch = useDispatch()
    const scrollToElement = useSelector(scrollSelector)

    console.warn(router.query)

    useEffect(() => {
        if (router.query) {
            if (router.query.category || router.query.productId) {
                setPromoModalVisibility(true)
            }
        }
    }, [])

    const [branchModalData, setBranchModalData] = useState({
        visible: false,
        productId: null
    })

    const [promoData, setPromoData] = useState({
        visible: false
    })

    useEffect(() => {
        dispatch(setMenu(null))
    }, [dispatch])

    /**
     * Function will scroll to element after certain amount of time.
     * It is called every time "scrollToElement" gets updated
     * It is called only when such element exists
     */
    useEffect(() => {
        if (scrollToElement) {
            setTimeout(() => {
                performScroll(scrollToElement)
                setupScrollElement(dispatch, null)
            }, 250)
        }
    }, [dispatch, scrollToElement])

    /**
     * @param {boolean} value Value of modal visibility
     * Function sets visibility of modal by value and performs scroll to top
     */
    const setModalVisibility = useCallback(value => {
        setBranchModalData({
            visible: value,
            productId: null
        })
        performScroll('topElement')
    }, [])

    const setPromoModalVisibility = useCallback(value => {
        setPromoData({
            visible: value
        })
        performScroll('topElement')
    }, [])

    /**
     * Function that opens modal with list of branches and performs scroll to top
     * Function sets productId which will then open modal with product by provided ID
     * @param {null} productId
     */
    const setModalProductId = useCallback(productId => {
        setBranchModalData({
            visible: true,
            productId: productId
        })
        performScroll('topElement')
    }, [])

    /**
     * Function dispatch event to openModal with provided data
     * @param {UrlObject | string} slug
     */
    const openProductDetail = useCallback(
        branchSlug => {
            setModalVisibility(false)
            openModalAsNewPage(router, branchModalData.productId, branchSlug)
        },
        [branchModalData.productId, router]
    )

    const openPromoDetail = useCallback(
        branchSlug => {
            setPromoData(false)
            openPromoPage(router, dispatch, branchSlug)
        },
        [router]
    )

    /**
     * Function will generate Banner Component with provided data
     * It will create specific banner, it depends on bannerType provided
     * @param {object} banner
     * @param {string} bannerType
     */
    const generateBanner = useCallback(
        (banner, bannerType) => {
            return (
                <Banner
                    bannerData={banner}
                    bannerType={bannerType}
                    modalAction={productId => setModalProductId(productId)}
                />
            )
        },
        [setModalProductId]
    )

    /**
     *  Function will generate Banner components under main Banner.
     *  It will use 1 or 2 banners, decided on how many will come from BE.
     */
    const generateBanners = useCallback(() => {
        return (
            <>
                {data && data.underSearchBanners && (
                    <div
                        className={`grid mb-8 xs:mb-0 ${
                            data.underSearchBanners.length === 1
                                ? 'grid-cols-1'
                                : 'grid-cols-1 xs:grid-cols-2 gap-y-2 sm:gap-y-0 gap-x-2 '
                        } `}
                    >
                        {data.underSearchBanners.map(banner => {
                            return (
                                <Fragment key={banner.id}>
                                    {generateBanner(banner, BannerTypeEnum.HP_UNDER_SEARCH)}
                                </Fragment>
                            )
                        })}
                    </div>
                )}
            </>
        )
    }, [data, generateBanner])

    return (
        <div className={'relative'}>
            {branchModalData.visible && (
                <BranchModal
                    regions={data.regions}
                    extraAction={openProductDetail}
                    subtitle={'Pobočku je nutné vybrat pro přídání produktu do košíku'}
                    setModalVisibility={setModalVisibility}
                />
            )}
            {promoData.visible && (
                <BranchModal
                    regions={data.regions}
                    extraAction={openPromoDetail}
                    subtitle={'Pobočku je nutné vybrat pro dokončení akce'}
                    setModalVisibility={setPromoModalVisibility}
                />
            )}
            <PopUpContainer events={data && data.events} />
            <Layout customData={data} showCity={false} footerType={FooterTypeEnum.HP_BRAND} visibleCart={false}>
                <div className={'relative'}>
                    {data && data.topBanner && generateBanner(data.topBanner, BannerTypeEnum.HP_TOP)}
                    <DeliveryBox />
                </div>
                <div className={'lg:container lg:mx-auto mt-64 xl:mt-40'}>{generateBanners()}</div>
                <SquaresPattern color={'#215929'} className={'xs:hidden'} />
                <div id={'regions'}>
                    <TitleBlock
                        title={'Naše pobočky'}
                        subtitle={`Najdete nás ve ${data && data.branches} městech`}
                        icon={<House />}
                    />
                    <div className={'container mx-auto'}>
                        <div className={'region-container'}>
                            {data &&
                                data.regions.map(region => (
                                    <div key={region.id}>
                                        <Region region={region} action={null} />
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className={'item-visibility'}>
                        {data && data.bottomBanner && generateBanner(data.bottomBanner, BannerTypeEnum.HP_BOTTOM)}
                    </div>
                </div>
                <SquaresPattern color={'#215929'} />
                <div className={'visible lg:hidden'}>
                    {data && data.bottomBanner && generateBanner(data.bottomBanner, BannerTypeEnum.HP_BOTTOM)}
                </div>
            </Layout>
        </div>
    )
}

export default connect()(Index)

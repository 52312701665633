import Region from '@/ui/components/region/brand/Region'
import { Fragment, useEffect, useState } from 'react'
import { AxiosClient } from '@/axios/axios.client'
import ModalContainer from '@/ui/components/modal/ModalContainer'

const BranchModal = ({ regions, subtitle, extraAction, setModalVisibility }) => {
    const [regionsData, setRegionsData] = useState(regions)

    useEffect(() => {
        if (!regionsData) {
            AxiosClient.get('/v1/web/hp/loadRegions')
                .then(response => {
                    if (response.data) {
                        setRegionsData(response.data.data.regions)
                    }
                })
                .catch(err => {
                    // captureException(err);
                    console.error(err)
                })
        }
    }, [regionsData])

    const redirectToBranch = (branchSlug, branchId) => {
        if (extraAction) {
            extraAction(branchSlug, branchId)
        }
    }

    const renderRegions = () => {
        let formattedRegions = []
        regionsData &&
            regionsData.map(region => {
                formattedRegions.push(
                    <Fragment key={region.id}>
                        <Region
                            region={region}
                            branchAction={(branchSlug, branchId) => redirectToBranch(branchSlug, branchId)}
                        />
                    </Fragment>
                )
            })
        return formattedRegions
    }
    return (
        <ModalContainer
            title={'Vyberte pobočku'}
            setModalVisibility={setModalVisibility}
            subtitle={subtitle}
            subtitleIcon={true}
        >
            <div
                /*
                style={{ height: useWindowSize().height / 1.5 }}
        */
                className={`region-container lg:px-20 mt-4 overflow-y-scroll no-scrollbar`}
            >
                {renderRegions()}
            </div>
        </ModalContainer>
    )
}
export default BranchModal

import { Fragment } from 'react'
import Branch from './Branch'

const Region = ({ region, branchAction }) => {
    const renderBranches = regBranches => {
        let arr = []
        regBranches.map(item => {
            arr.push(
                <Fragment key={item.id}>
                    <Branch branchData={item} branchAction={branchAction} />
                </Fragment>
            )
        })
        return arr
    }

    return (
        <div>
            <h3 className={'fk-screamer-h3 text-center'}>{region.name}</h3>
            {region.branches && (
                <div className={'grid grid-flow-row gap-3 grid-cols-1 xs:grid-cols-2 mt-4'}>
                    {renderBranches(region.branches)}
                </div>
            )}
        </div>
    )
}
export default Region

import { useState } from 'react'
import BranchArrow from '../../../icons/BranchArrow'
import { useDispatch } from 'react-redux'
import { changeDeliveryType, setOrderData } from '@/redux/actions/orderAction'
import { WarePriceTypeEnum } from '@/constants/constants'
import { useRouter } from 'next/router'

const Branch = ({ branchData, branchAction }) => {
    const router = useRouter()
    const [infoVisible, setInfoVisible] = useState(false)
    const dispatch = useDispatch()

    const redirectToBranch = () => {
        if (branchData.active) {
            dispatch(changeDeliveryType(WarePriceTypeEnum.INHOUSE))
            dispatch(
                setOrderData({
                    id: branchData.speedloId,
                    city: branchData.city,
                    url: branchData.slug,
                    gps: null,
                    unFormattedAddress: null
                })
            )
            if (branchAction) branchAction(branchData.slug, branchData.speedloId)
            else {
                router.push(branchData.slug)
            }
        }
    }

    const changeInfoVisibility = (event, value) => {
        event.stopPropagation()
        setInfoVisible(value)
    }

    return (
        <div
            onClick={redirectToBranch}
            onMouseOver={event => changeInfoVisibility(event, true)}
            onMouseOut={event => changeInfoVisibility(event, false)}
            className={`rounded-xl flex flex-row ${
                branchData.tag ? 'pl-0' : 'pl-4 py-3'
            }   items-center justify-between  text-left 
                ${
                    branchData.active
                        ? 'button-background-solid text-turbo-secondary hover:text-white cursor-pointer'
                        : 'bg-turbo-grayer'
                }`}
        >
            <div className={'flex items-center'}>
                {branchData.tag && (
                    <div className={'relative'}>
                        {infoVisible && (
                            <div
                                className={
                                    'z-10 border-1 border-black absolute bg-turbo-primary top-6 left-6 p-4 rounded-xl text-white rounded-tl-none font-libre_franklin'
                                }
                            >
                                {branchData.tag}
                            </div>
                        )}
                    </div>
                )}
                {branchData.tag && (
                    <div
                        className={'bg-turbo-primary text-white w-6 py-4 mr-2 rounded-l-xl'}
                        onClick={event => changeInfoVisibility(event, !infoVisible)}
                    >
                        <div className={'text-center font-bold'}>i</div>
                    </div>
                )}
                <div className={'text-lg xl:text-xl  font-fk_screamer tracking-0-04em pr-1'}>
                    {branchData.name.split('-')[1]}
                </div>
            </div>

            {branchData.active && (
                <div className={'pr-4'}>
                    <BranchArrow />
                </div>
            )}
        </div>
    )
}
export default Branch

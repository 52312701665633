import Image from 'next/image'
import { useEffect } from 'react'
import CloseBigger from '@/ui/icons/CloseBigger'
import { checkCookies, setCookies } from 'cookies-next'

function savePopUptoCookie(eventId) {
    setCookies('POP_UP_' + eventId, true, { secure: false, maxAge: 60 * 60 * 2 })
}

const PopUp = ({ event, setVisible, setEffect }) => {
    useEffect(() => {
        const found = checkCookies('POP_UP_' + event.id)
        if (!found) {
            setVisible(true)
        }
    })

    const changeSetEffect = () => {
        setEffect(false)
    }
    const changeMenuVisibility = event => {
        setEffect(true)
        setVisible(false)
        savePopUptoCookie(event.id)
    }

    return (
        <>
            <div className={'w-full relative lg:container mx-auto float-left sm:flex'} key={event.id}>
                <div className={'float-left md:flex justify-start sm:justify-center'}>
                    {event.image && (
                        <div className={'w-20 h-20 relative mr-4 lg:mr-8 rounded-2xl overflow-hidden mb-4 md:mb-0'}>
                            <Image
                                alt={event.image}
                                placeholder={'blur'}
                                blurDataURL={event.image}
                                src={event.image}
                                layout={'fill'}
                                objectPosition={'center'}
                                objectFit={'cover'}
                            />
                        </div>
                    )}
                </div>
                <div className={'mr-10 md:mr-14 text-base'}>
                    <div dangerouslySetInnerHTML={{ __html: event.content }} />
                </div>
                <div
                    className={'absolute right-0 top-0 hover:cursor-pointer'}
                    onAnimationEnd={changeSetEffect}
                    onClick={() => changeMenuVisibility(event)}
                >
                    <CloseBigger />
                </div>
            </div>
        </>
    )
}
export default PopUp

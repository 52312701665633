const Delivery = props => (
    <svg width={36} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g>
            <path
                d="M35.095 17.816h-3.593a.892.892 0 0 0-.892.903.89.89 0 0 0 .892.89h3.593a.893.893 0 0 0 .64-1.529.906.906 0 0 0-.64-.264Z"
                fill="#E30A18"
            />
            <path
                d="M32.177 22.206H18.312a12.05 12.05 0 0 0 3.008-2.622h6.6a.893.893 0 0 0 .64-1.53.905.905 0 0 0-.64-.264h-5.39A11.873 11.873 0 0 0 24.034 12c0-2.373-.705-4.693-2.025-6.667a12.014 12.014 0 0 0-5.393-4.42A12.033 12.033 0 0 0 3.52 3.515 11.995 11.995 0 0 0 .915 16.592a12.003 12.003 0 0 0 4.426 5.386A12.029 12.029 0 0 0 12.017 24h1.121c.102.014.205.014.306 0h18.733a.893.893 0 0 0 .64-1.53.905.905 0 0 0-.64-.264ZM4.741 12.026a7.259 7.259 0 0 1 4.491-6.714 7.286 7.286 0 0 1 7.93 1.575 7.262 7.262 0 0 1-1.102 11.18A7.284 7.284 0 0 1 5.287 14.79 7.257 7.257 0 0 1 4.741 12v.025Z"
                fill="#E30A18"
            />
            <path
                d="M11.966 15.194A3.196 3.196 0 0 0 15.165 12a3.196 3.196 0 0 0-3.199-3.194A3.196 3.196 0 0 0 8.767 12a3.196 3.196 0 0 0 3.199 3.194Z"
                fill="#215929"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h36v24H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default Delivery

const CheckBox = props => (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M10 0a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm4.78 7.7-5.67 5.66a.75.75 0 0 1-1.06 0l-2.83-2.83a.75.75 0 0 1 1.06-1.06l2.3 2.3 5.14-5.13a.74.74 0 0 1 1.06 0 .75.75 0 0 1 0 1.06Z"
            fill="#E30A18"
        />
    </svg>
)

export default CheckBox

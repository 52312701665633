import { useCallback } from 'react'
import { renderIcon } from '@/ui/components/user/address/AddressBoxView'
import CheckBox from '@/ui/icons/CheckBox'
import ShadowContainer from '@/ui/components/shared/ShadowContainer'

const SavedUserAddress = ({ item, selected, onClick }) => {
    const changeAddress = useCallback(() => {
        onClick(item)
    }, [onClick])

    const renderWholeAddress = () => {
        const { location } = item
        return `${location.street} ${location.houseNumber}, ${location.city}`
    }

    return (
        <ShadowContainer customStyle={' px-4 py-4 min-h-[70px]'} onClick={changeAddress}>
            <div className={'flex flex-row items-center justify-between '}>
                <div className={'flex flex-row items-center'}>
                    <div className={'pr-4'}>{renderIcon(item.addressType.enum)}</div>
                    <div className={'flex flex-col'}>
                        <span className={'font-libre_franklin_bold text-base'}>
                            {item.title ? item.title : item.addressType.code}
                        </span>
                        <span className={'font-libre_franklin text-sm'}>{renderWholeAddress()}</span>
                    </div>
                </div>
                <div className={'ml-4 w-5 h-5  '}>
                    {selected ? <CheckBox /> : <div className={'w-5 h-5 rounded-full border'} />}
                </div>
            </div>
        </ShadowContainer>
    )
}

export default SavedUserAddress

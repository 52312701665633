import Link from 'next/link'
import { BannerTypeEnum } from '@/constants/constants'
import { openModalAsNewPage } from '@/functions/modal/modalControls'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'

const Banner = ({ bannerType, bannerData, modalAction }) => {
    const router = useRouter()
    const hasLink = bannerData.link !== ''
    const hasProductId = bannerData.speedloProductId !== null
    const [width, setWidth] = useState()
    const isMobile = width < 800

    console.debug(bannerData)
    const clickableBanner = hasLink || hasProductId

    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }

    useEffect(() => {
        if (window) setWidth(window.innerWidth)
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    const handleClickAction = useCallback(() => {
        if (hasProductId) {
            if (!modalAction) {
                openModalAsNewPage(router, bannerData.speedloProductId)
            } else {
                modalAction(bannerData.speedloProductId)
            }
        }
    }, [bannerData.speedloProductId, hasProductId, modalAction, router])

    const generateImage = (priority = false) => {
        return (
            <Image
                priority={priority}
                layout={'fill'}
                objectFit={isMobile ? 'contain' : 'contain'}
                objectPosition={'center top'}
                src={
                    isMobile
                        ? !bannerData.mobileImage.includes('null')
                            ? bannerData.mobileImage
                            : bannerData.image
                        : bannerData.image
                }
                as={'image'}
                unoptimized
            />
        )
    }

    const generateBannerContainer = () => {
        if (bannerType === BannerTypeEnum.HP_TOP) return isMobile ? 'banner-hp-top-mobile' : 'banner-hp-top'
        else if (bannerType === BannerTypeEnum.HP_UNDER_SEARCH)
            return isMobile ? 'banner-hp-undersearch-mobile' : 'banner-hp-undersearch'
        else if (bannerType === BannerTypeEnum.HP_BOTTOM)
            return `lg:container lg:mx-auto banner-hp-bottom  md:mb-12 md:mt-12 lg:mb-36 lg:mt-0 ${
                isMobile ? 'banner-hp-bottom-mobile' : 'banner-hp-bottom'
            }`
        else if (bannerType === BannerTypeEnum.BRANCH_TOP)
            return isMobile ? 'banner-branch-top-mobile' : 'banner-branch-top'
        else if (bannerType === BannerTypeEnum.BRANCH_CATEGORY)
            return 'rounded-xl lg:rounded-2xl xl:rounded-3xl  2xl:rounded-[2rem] overflow-hidden relative flex flex-col banner-branch-category relative'
        else if (bannerType === BannerTypeEnum.BRANCH_BOTTOM)
            return ` mx-5 mb-5 lg:mx-0  lg:mb-0 mt-5 ${
                isMobile ? 'banner-branch-bottom-mobile' : 'banner-branch-bottom'
            }`
    }

    const generateBody = () => {
        return (
            <div
                onClick={handleClickAction}
                className={generateBannerContainer() + ' relative ' + (clickableBanner && 'hover:cursor-pointer')}
            >
                {generateImage(bannerType === BannerTypeEnum.HP_TOP || bannerType === BannerTypeEnum.BRANCH_TOP)}
            </div>
        )
    }

    return (
        <>
            {clickableBanner ? (
                <Fragment>
                    {hasLink ? (
                        <Link href={`${bannerData.link}`}>
                            <a
                                target={`${bannerData.isBlank ? '_blank' : ''}`}
                                rel="noopener noreferrer"
                                className="link-item"
                            >
                                {generateBody()}
                            </a>
                        </Link>
                    ) : (
                        hasProductId && generateBody()
                    )}
                </Fragment>
            ) : (
                generateBody()
            )}
        </>
    )
}
export default Banner

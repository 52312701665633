function ShadowContainer({ children, onClick, customStyle, hoverAble = true }) {
    return (
        <div
            onClick={() => onClick && onClick(true)}
            className={`${customStyle}  shadow-container relative  mb-4 rounded-xl items-center min-h-14 ${
                hoverAble && 'hover:cursor-pointer'
            }`}
        >
            {children}
        </div>
    )
}

export default ShadowContainer

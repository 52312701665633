const BranchArrow = props => (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M14.19 0H5.81C2.17 0 0 2.17 0 5.81v8.38C0 17.83 2.17 20 5.81 20h8.38c3.64 0 5.81-2.17 5.81-5.81V5.81C20 2.17 17.83 0 14.19 0Zm2.34 10.53-4.29 4.29a.75.75 0 0 1-.53.22.79.79 0 0 1-.53-.22.75.75 0 0 1 0-1.06l3-3H4a.75.75 0 1 1 0-1.5h10.19l-3-3a.75.75 0 0 1 1.06-1.06l4.29 4.29a.75.75 0 0 1 0 1.06l-.01-.02Z"
            fill="#E30A18"
        />
    </svg>
)

export default BranchArrow

import { Fragment, useCallback, useState } from 'react'
import PopUp from '@/ui/components/popup/PopUp'

const PopUpContainer = ({ events }) => {
    const [visible, setVisible] = useState(false)
    const [effect, setEffect] = useState(true)

    const changeVisible = useCallback(value => {
        setVisible(value)
    }, [])

    const changeEffect = useCallback(value => {
        setEffect(value)
    }, [])

    if (!events) return <></>

    return (
        <div
            className={` flex justify-between z-10 w-full items-center pl-6 pr-4 lg:px-0 bg-turbo-background py-4 ${
                visible ? 'visible' : 'hidden'
            } ${effect && 'animate-fade-in-down'}`}
        >
            {events.map(event => {
                return (
                    <Fragment key={event.id}>
                        <PopUp event={event} setVisible={changeVisible} setEffect={changeEffect} />
                    </Fragment>
                )
            })}
        </div>
    )
}

export default PopUpContainer
